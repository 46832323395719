import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Paper, Stack, Typography } from '@mui/material';
import Main from '../../Generic/Form/MainReinitialize';
import { my_ssn_validations } from '../../../helpers/functions/functions';
import { formValueSelector, change, updateSyncErrors, reset, untouch, blur, destroy } from 'redux-form';
import { patchTicketInviteUserAction, postTicketInviteAction, postTicketInviteUserAction } from '../actions/ticketsActions';
import { cleanContactsAction, getContactsAction } from '../../Users/actions/usersActions';
import { fetchConditionsAction } from '../../Conditions/actions/conditionsActions';
import { TYPES as FORM_TYPES } from '../../Generic/FormTypes';
import USER from '../../../helpers/user';
import { displayModalAction, fetchGroupsAction } from '../../Generic/actions/genericActions';
import CloseIcon from '@mui/icons-material/Close';

class InviteSingleForm extends Component {
	constructor(props) {
		super(props);

		this.initialState = {
			invalid_required_fields: true,
			search: false,
			searching: false,
		};
		this.state = this.initialState;
	}

	componentWillUnmount() {
		this.props.destroy('ticket_invite');
	}

	componentDidMount() {
		this.props.fetchConditionsAction();
		this.props.cleanContactsAction();
		if (
			this.props.required_fields.find((elem) => elem.field == 'groups_id') &&
			this.props.required_fields.find((elem) => elem.field == 'groups_id').display
		) {
			this.props.fetchGroupsAction();
		}
	}

	handleSubmitButton = (form) => {
		if (this.props.user_id_field) {
			this.props.patchTicketInviteUserAction(form, (user_id) => {
				if (user_id) {
					this.props.postTicketInviteAction(form, user_id);
				}
			});
		} else {
			this.props.postTicketInviteUserAction(form, (user_id) => {
				if (user_id) {
					this.props.postTicketInviteAction(form, user_id);
				}
			});
		}
	};

	clearSearch = (ssn) => {
		this.setState(this.initialState, () => {
			this.props.reset('ticket_invite');
		});
	};
	searchUser = (value) => {
		this.setState((state) => ({
			...state,
			searching: true,
			search: true,
			// invalid_required_fields: false,
		}));
		this.props.getContactsAction(
			value,
			{
				origins: [USER.ORIGINS.UNKNOWN, USER.ORIGINS.SYNCRONIZED, USER.ORIGINS.MANUAL, USER.ORIGINS.INVITED].join(','),
				types: [USER.TYPES.CUSTOMER, USER.TYPES.EXTERNAL, USER.TYPES.STAFF].join(','),
				status: '10,100',
			},
			(data) => {
				if (data.items.length === 1 && (data.items[0].ssn === this.props.ssn_field || data.items[0].email === this.props.email_field)) {
					this.props.displayModalAction({
						id: 'ticket_invite_contact_found_modal',
						title: this.props.translate('ticket_invite_modal_title'),
						description: (
							<Stack>
								{this.props.translate('ticket_invite_modal_description')}
								<Typography variant="subtitle1">
									<b>{data.items[0].name}</b>
								</Typography>
								<Stack direction={'row'}>
									<Typography variant="subtitle1" mr={1}>
										{/**TODO: add translations tags on field labels */}
										<b>{'SSN:'}</b>
									</Typography>
									<Typography variant="body2" alignSelf={'center'}>
										{data.items[0].ssn}
									</Typography>
								</Stack>
								<Stack direction={'row'}>
									<Typography variant="subtitle1" mr={1}>
										<b>{'Email: '}</b>
									</Typography>
									<Typography variant="body2" alignSelf={'center'}>
										{data.items[0].email}
									</Typography>
								</Stack>
							</Stack>
						),
						button_no: this.props.translate('generic_button_no'),
						button_yes: this.props.translate('generic_yes'),
						button_no_id: 'ticket_invite_contact_found_modal_button_no',
						action_no_button: () => {
							this.setState(
								{
									searching: false,
									search: false,
									invalid_required_fields: true,
								},
								() => {
									if (data.items[0].ssn === this.props.ssn_field) {
										this.props.updateSyncErrors('ticket_invite', {
											ssn: this.props.translate('ticket_invite_ssn_field_error_duplicated'),
										});
										// this.props.blur('ticket_invite', 'ssn', this.props.ssn_field)
										// this.props.untouch('ticket_invite', ['ssn']);
									}
									if (data.items[0].email === this.props.email_field) {
										this.props.updateSyncErrors('ticket_invite', {
											email: this.props.translate('ticket_invite_email_field_error_duplicated'),
										});
										// this.props.blur('ticket_invite', 'email', this.props.email_field)
										// this.props.untouch('ticket_invite', ['email']);
									}
								}
							);
						},
						action: () => {
							this.setState(
								(state) => ({
									...state,
									searching: false,
									search: false,
									invalid_required_fields: false,
								}),
								() => {
									this.props.change('ticket_invite', 'user_id', data.items[0].id);
									this.props.change('ticket_invite', 'ssn', data.items[0].ssn);
									this.props.change('ticket_invite', 'email', data.items[0].email);
									this.props.change('ticket_invite', 'first_name', data.items[0].first_name);
									this.props.change('ticket_invite', 'last_name', data.items[0].last_name);
									this.props.change('ticket_invite', 'gender', data.items[0].gender);
									if (data.items[0].pronoun) {
										this.props.change('ticket_invite', 'pronoun', data.items[0].pronoun);
									}
									this.props.change('ticket_invite', 'pronoun', data.items[0].pronoun);
									this.props.change('ticket_invite', 'phone', data.items[0].phone);
									this.props.change('ticket_invite', 'birthdate', data.items[0].birthdate);
									this.props.change('ticket_invite', 'groups_id', data.items[0].group.id);
								}
							);
						},
						action_on_close: () => {
							this.setState(
								{
									searching: false,
									search: false,
									invalid_required_fields: true,
								},
								() => {
									if (data.items[0].ssn === this.props.ssn_field) {
										this.props.updateSyncErrors('ticket_invite', {
											ssn: 'SSN already exists',
										});
									}
									if (data.items[0].email === this.props.email_field) {
										this.props.updateSyncErrors('ticket_invite', {
											email: 'Email already exists',
										});
									}
								}
							);
						},
					});
				} else {
					this.setState((state) => ({
						...state,
						searching: false,
						search: false,
						invalid_required_fields:
							(!this.props.email_field && this.props.required_fields.find((elem) => elem.field == 'ssn')?.required) ||
							(!this.props.ssn_field && this.props.required_fields.find((elem) => elem.field == 'ssn')?.required),
					}));
				}
			},
			() => {
				this.setState((state) => ({
					...state,
					searching: false,
					search: false,
					invalid_required_fields: false,
				}));
			}
		);
	};

	handleConfig = () => {
		const { translate } = this.props;
		let conditions = [];
		if (this.props.conditions) {
			this.props.conditions.map((condition, index) => {
				let childs = [];
				condition.conditions.map((child, index) =>
					childs.push({
						label: child.name,
						value: child.id,
					})
				);
				conditions.push({
					parent: condition.name,
					options: childs,
				});
			});
		}
		const structure = {
			form: 'ticket_invite',
			title: '',
			subtitle: '',
			helperText: '',
			// maxWidth: 'sm',
			fields_grid_layout: {
				xs: 12,
			},
			buttons_grid_layout: {
				xs: 12,
				content_align: 'center',
				spacing: 2,
			},
			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'user_id',
									name: 'user_id',
									label: '',
									type: FORM_TYPES.HIDDEN,
								},
								{
									id: 'ssn',
									name: 'ssn',
									label: translate('ticket_form_invite_field_ssn'),
									type: this.props.required_fields.find((elem) => elem.field == 'ssn')?.display
										? FORM_TYPES.INPUT_ADORNMENT
										: FORM_TYPES.NONE,
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'ssn')?.required,
										...(this.props.required_fields.find((elem) => elem.field === 'ssn') &&
										this.props.required_fields.find((elem) => elem.field === 'ssn').validation
											? {
													functionValidation: [
														{
															validation: (value) => {
																const ssnField = this.props.required_fields.find((elem) => elem.field === 'ssn');
																if (ssnField && ssnField.validation) {
																	return ssnField.validation.some((validationType) =>
																		my_ssn_validations[validationType](value)
																	);
																}
																return true;
															},
														},
													],
											  }
											: {}),
									},
									onValidValue: (value) => {
										if (!this.state.search && !this.props.user_id_field) {
											this.searchUser(value);
										}
									},
									adornment: {
										action: () => {
											if (!this.props.user_id_field) {
												this.searchUser();
											} else {
												this.clearSearch();
											}
										},
										triggered: this.state.searching,
										...(this.props.user_id_field && {
											icon: <CloseIcon />,
										}),
									},
									readonly: this.props.user_id_field,
								},
								{
									id: 'email',
									name: 'email',
									label: translate('ticket_form_invite_field_email'),
									type: this.props.required_fields.find((elem) => elem.field == 'email')?.display
										? FORM_TYPES.INPUT_ADORNMENT
										: FORM_TYPES.NONE,
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'email')?.required,
										email: true,
									},
									onValidValue: (value) => {
										if (!this.state.search && !this.props.user_id_field) {
											this.searchUser(value);
										}
									},
									adornment: {
										action: () => {
											if (!this.props.user_id_field) {
												this.searchUser();
											} else {
												this.clearSearch();
											}
										},
										triggered: this.state.searching,
										...(this.props.user_id_field && {
											icon: <CloseIcon />,
										}),
									},
									readonly: this.props.user_id_field,
								},
								...(this.props.settings?.tickets?.['invite.name_search']
									? [
											{
												id: 'first_name',
												name: 'first_name',
												label: translate('users_form_myprofile_field_first_name'),
												getOptionLabel: (option) => {
													return option?.name ?? '';
												},
												type: FORM_TYPES.AUTOCOMPLETE_TEXTFIELD,
												freeSolo: true,
												options: this.props.contacts_list,
												onInputChange: (event, newInputValue, reason) => {
													console.log(reason);
													if (reason === 'clear') {
														this.clearSearch();
													}
												},
												onSelect: (option) => {
													console.log(option);
													if (option) {
														this.props.displayModalAction({
															id: 'ticket_invite_contact_found_modal',
															title: this.props.translate('ticket_invite_modal_title'),
															description: (
																<Stack>
																	{this.props.translate('ticket_invite_modal_description')}
																	<Typography variant="subtitle1">
																		<b>{option.name}</b>
																	</Typography>
																	<Stack direction={'row'}>
																		<Typography variant="subtitle1" mr={1}>
																			{/**TODO: add translations tags on field labels */}
																			<b>{'SSN:'}</b>
																		</Typography>
																		<Typography variant="body2" alignSelf={'center'}>
																			{option.ssn}
																		</Typography>
																	</Stack>
																	<Stack direction={'row'}>
																		<Typography variant="subtitle1" mr={1}>
																			<b>{'Email: '}</b>
																		</Typography>
																		<Typography variant="body2" alignSelf={'center'}>
																			{option.email}
																		</Typography>
																	</Stack>
																</Stack>
															),
															button_no: this.props.translate('generic_button_no'),
															button_yes: this.props.translate('generic_yes'),
															button_no_id: 'ticket_invite_contact_found_modal_button_no',
															action_no_button: () => {
																this.setState(
																	{
																		searching: false,
																		search: false,
																		invalid_required_fields: true,
																	},
																	() => {
																		if (option.ssn === this.props.ssn_field) {
																			this.props.updateSyncErrors('ticket_invite', {
																				ssn: this.props.translate('ticket_invite_ssn_field_error_duplicated'),
																			});
																			// this.props.blur('ticket_invite', 'ssn', this.props.ssn_field)
																			// this.props.untouch('ticket_invite', ['ssn']);
																		}
																		if (option.email === this.props.email_field) {
																			this.props.updateSyncErrors('ticket_invite', {
																				email: this.props.translate(
																					'ticket_invite_email_field_error_duplicated'
																				),
																			});
																			// this.props.blur('ticket_invite', 'email', this.props.email_field)
																			// this.props.untouch('ticket_invite', ['email']);
																		}
																	}
																);
															},
															action: () => {
																this.setState(
																	(state) => ({
																		...state,
																		searching: false,
																		search: false,
																		invalid_required_fields: false,
																	}),
																	() => {
																		this.props.change('ticket_invite', 'user_id', option.id);
																		this.props.change('ticket_invite', 'ssn', option.ssn);
																		this.props.change('ticket_invite', 'email', option.email);
																		this.props.change('ticket_invite', 'first_name', option.first_name);
																		this.props.change('ticket_invite', 'last_name', option.last_name);
																		this.props.change('ticket_invite', 'gender', option.gender);
																		if (option.pronoun) {
																			this.props.change('ticket_invite', 'pronoun', option.pronoun);
																		}
																		this.props.change('ticket_invite', 'phone', option.phone);
																		this.props.change('ticket_invite', 'birthdate', option.birthdate);
																		this.props.change('ticket_invite', 'groups_id', option.group.id);
																	}
																);
															},
															action_on_close: () => {
																this.setState(
																	{
																		searching: false,
																		search: false,
																		invalid_required_fields: true,
																	},
																	() => {
																		if (option.ssn === this.props.ssn_field) {
																			this.props.updateSyncErrors('ticket_invite', {
																				ssn: 'SSN already exists',
																			});
																		}
																		if (option.email === this.props.email_field) {
																			this.props.updateSyncErrors('ticket_invite', {
																				email: 'Email already exists',
																			});
																		}
																	}
																);
															},
														});
													}
												},
												onChange: (value, event_value) => {
													console.log('test', value);
													console.log('test', event_value);
													this.props.getContactsAction(event_value, {
														origins: [
															USER.ORIGINS.UNKNOWN,
															USER.ORIGINS.SYNCRONIZED,
															USER.ORIGINS.MANUAL,
															USER.ORIGINS.INVITED,
														].join(','),
														types: [USER.TYPES.CUSTOMER, USER.TYPES.EXTERNAL, USER.TYPES.STAFF].join(','),
													});
												},
												readonly: this.props.user_id_field,
											},
									  ]
									: [
											{
												id: 'first_name',
												name: 'first_name',
												label: translate('users_form_myprofile_field_first_name'),
												type: this.props.required_fields.find((elem) => elem.field == 'name')?.display
													? FORM_TYPES.INPUT
													: FORM_TYPES.NONE,
												validate: {
													required: this.props.required_fields.find((elem) => elem.field == 'name')?.required,
												},
												readonly: this.state.invalid_required_fields || this.props.user_id_field || this.state.search,
											},
									  ]),
								{
									id: 'last_name',
									name: 'last_name',
									label: translate('users_form_myprofile_field_last_name'),
									type: this.props.required_fields.find((elem) => elem.field == 'name')?.display
										? FORM_TYPES.INPUT
										: FORM_TYPES.NONE,
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'name')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'gender',
									name: 'gender',
									label: translate('users_form_myprofile_field_gender'),
									type: this.props.required_fields.find((elem) => elem.field == 'gender')?.display
										? FORM_TYPES.SELECT
										: FORM_TYPES.NONE,
									options: this.props.required_fields
										.find((elem) => elem.field == 'gender')
										.options.map((gender) => ({
											label: translate('users_form_myprofile_field_gender_option_' + gender),
											value: gender,
										})),
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'gender')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'pronoun',
									name: 'pronoun',
									label: translate('users_form_field_pronoun'),
									type: this.props.required_fields.find((elem) => elem.field == 'pronoun')?.display
										? FORM_TYPES.SELECT
										: FORM_TYPES.NONE,
									options: this.props.required_fields
										.find((elem) => elem.field == 'pronoun')
										.options.map((pronoun) => ({
											label: translate('users_form_field_pronoun_option_' + pronoun),
											value: pronoun,
										})),
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'pronoun')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'birthdate',
									name: 'birthdate',
									label: translate('users_form_personal_data_field_birthdate'),
									type: this.props.required_fields.find((elem) => elem.field == 'birthdate')?.display
										? FORM_TYPES.DATEPICKER
										: FORM_TYPES.NONE,
									openTo: 'year',
									picker_type: 'birth',
									disableFuture: true,
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'birthdate')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'phone',
									name: 'phone',
									label: translate('ticket_form_invite_field_phone'),
									type: this.props.required_fields.find((elem) => elem.field == 'phone')?.display
										? FORM_TYPES.PHONE
										: FORM_TYPES.NONE,
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'phone')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'groups_id',
									name: 'groups_id',
									label: translate('ticket_form_invite_field_groups'),
									type: this.props.required_fields.find((elem) => elem.field == 'groups_id')?.display
										? FORM_TYPES.SELECT
										: FORM_TYPES.NONE,
									options:
										this.props.groups &&
										this.props.groups.map((obj) => {
											return {
												value: obj.id,
												label: obj.name,
											};
										}),
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'groups_id')?.required,
									},
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'conditions_id',
									name: 'conditions_id',
									label: translate('ticket_form_invite_field_conditions_id'),
									type: FORM_TYPES.SELECT_GROUP,
									options: conditions,
									validate: {
										required: true,
									},
									...(conditions.length == 1 && conditions[0].options.length == 1
										? { initialvalue: conditions[0].options[0].value }
										: {}),
								},
								{
									id: 'reference',
									name: 'reference',
									label: translate('ticket_form_invite_field_reference'),
									type:
										typeof this.props.settings.feature !== 'undefined' && this.props.settings.feature.tickets_reference
											? FORM_TYPES.TEXTAREA
											: FORM_TYPES.NONE,
									readonly:
										this.state.invalid_required_fields || typeof this.props.user_id_field !== 'undefined' || this.state.search,
								},
								{
									id: 'message',
									name: 'message',
									label: translate('ticket_form_invite_field_message'),
									type: FORM_TYPES.TEXTAREA,
								},
								{
									id: 'assign_case_to_me',
									name: 'assign_case_to_me',
									label: '',
									type: FORM_TYPES.CHECKBOX,
									options: [
										{
											label: translate('ticket_form_invite_field_assign_case_to_me'),
											value: '1',
										},
									],
									initialvalue: '1',
								},
								{
									id: 'type',
									name: 'type',
									label: translate('users_form_myprofile_field_contractor'),
									type: this.props.required_fields.find((elem) => elem.field == 'type')?.display
										? FORM_TYPES.SELECT
										: FORM_TYPES.NONE,
									options: [
										{
											label: translate('users_form_myprofile_field_type_option_customer'),
											value: 0,
										},
										{
											label: translate('users_form_myprofile_field_type_option_staff'),
											value: 1,
										},
										{
											label: translate('users_form_myprofile_field_type_option_external'),
											value: 2,
										},
									],
									validate: {
										required: this.props.required_fields.find((elem) => elem.field == 'type')?.required,
									},
									initialvalue: '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'invite_submit_button',
					fullWidth: true,
					label: translate('ticket_form_invite_button_save'),
					grid_layout: { xs: 12 },
					action: () => this.handleSubmitButton('ticket_invite'),
					reset_on_submit: false,
					api_validation: true,
				},
			],
		};
		return structure;
	};
	render() {
		const configuration = this.handleConfig();
		const { translate } = this.props;

		return (
			<div>
				<Typography align="center" variant="h2" component="h2" gutterBottom>
					{translate('ticket_form_invite_title')}
				</Typography>
				<Typography align="center" variant="subtitle2" component="p" gutterBottom>
					{translate('ticket_form_invite_subtitle')}
				</Typography>
				<Main form={configuration.form} formData={configuration} />
			</div>
		);
	}
}
const selector = formValueSelector('ticket_invite');
const mapStateToProps = (state) => ({
	user: state.users.whoami,
	ticket: state.tickets.ticket,
	conditions: state.conditions.conditions,
	settings: state.settings,
	groups: state.generic.groups,
	translate: getTranslate(state.localize),
	required_fields: state.settings.users.required_fields,
	ssn_field: selector(state, 'ssn'),
	email_field: selector(state, 'email'),
	user_id_field: selector(state, 'user_id'),
	contacts_list: state.users.contacts_list?.items ?? [],
});
export default connect(mapStateToProps, {
	patchTicketInviteUserAction,
	postTicketInviteUserAction,
	getContactsAction,
	fetchConditionsAction,
	displayModalAction,
	change,
	updateSyncErrors,
	reset,
	destroy,
	postTicketInviteAction,
	untouch,
	blur,
	fetchGroupsAction,
	cleanContactsAction,
})(InviteSingleForm);
