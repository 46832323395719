import { apiRequest } from '../../../helpers/functions/request';
import { store } from '../../../store';
import { getTranslate } from 'react-localize-redux';
import { FETCH_SURVEYS, SET_SURVEY, UPDATE_QUESTION, CLEAR_SURVEY } from './types';
import { FETCH_TICKET_SURVEYS } from '../../Tickets/actions/types';
import { createAnalyticAction } from '../../Analytic/actions/analyticActions';
import ANALYTIC from '../../../helpers/analytic_logs';
import { fetchTicketbyTokenAction } from '../../Tickets/actions/ticketsActions';
import { parseDateOffset } from '../../../helpers/functions/functions';
import TICKET from '../../../helpers/ticket';
/**
 * Get Ticket surveys list
 * @param {int} ticket_id Ticket id
 * @returns
 */
export const getSurveysAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/surveys`,
	});

	dispatch({
		type: FETCH_SURVEYS,
		payload: response.data,
	});
};

/**
 * Get Ticket surveys list
 * @param {int} ticket_id Ticket id
 * @returns
 */
export const getTicketSurveysAction = (ticket_id) => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + ticket_id + `/surveys`,
		params: { history: false, limit: 999 },
	});

	dispatch({
		type: FETCH_TICKET_SURVEYS,
		payload: response.data,
	});
};

/**
 * Get User Ticket surveys list
 * @param {int} ticket_id Ticket id
 * @returns
 */
export const getUserTicketSurveysAction = (ticket_id, user_id) => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + ticket_id + `/users/` + user_id + `/surveys`,
		params: { history: false, limit: 999 },
	});

	const ticket_surveys = response.data.items.filter((surv) => surv.ticket_id === ticket_id && surv.type !== 'file');

	const user_surveys = response.data.items.filter((surv) => surv.ticket_id !== ticket_id && surv.type !== 'file');

	const medical_records = response.data.items.filter((surv) => surv.type === 'file');

	response.data.items = [...ticket_surveys, ...user_surveys, ...medical_records];

	dispatch({
		type: FETCH_TICKET_SURVEYS,
		payload: response.data,
	});
};

/**
 * Get Survey current structure
 * @param {int} survey_id Survey id
 * @returns
 */
export const getSurveyAction = (ticket_id, survey_id) => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + survey_id,
		params: { history: true },
	});

	dispatch({
		type: SET_SURVEY,
		payload: response.data,
	});
};

/**
 * Assign a Suervey to a User
 * @param {int} survey_id Survey id
 * @param {int} user Target user id
 * @param {string} contact_method Contact method
 * @returns
 */
export const assignSurveyAction =
	({ ticket_id, survey_id, user_id, contact_method = false, contact_type = false, callback = false }) =>
	async (dispatch) => {
		const state = store.getState();

		const survey_name = state.surveys.items.find((surv) => surv.id == survey_id)?.name;

		var data = new FormData();
		data.append('user_id', user_id);
		data.append('survey_id', survey_id);

		if (contact_method) {
			data.append('contact_method', contact_method);
		}

		if (contact_type) {
			data.append('contact_type', contact_type);
		}

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/assign`,
			data,
		});

		await dispatch(
			createAnalyticAction(ANALYTIC.CREATE_JOURNAL, 'tickets', state.tickets.ticket.id, {
				name: survey_name,
			})
		);
		if (callback) {
			callback({ survey: response.data, ticket_id });
		}
		return dispatch(getTicketSurveysAction(ticket_id));
	};

/**
 * Post survey question answer
 * @param {int} survey_id Survey id
 * @param {int} question_id Question id
 * @param {string} value question answer
 * @param {string} form Redux Form survey unique identifier
 * @param {string} field Redux Form field unique identifier
 * @returns
 */
export const postAnswerAction =
	({ ticket_id, response_id, question_id, form = 'survey', value = '', type = 'string', callback = false }) =>
	async (dispatch) => {
		const state = store.getState();

		var data = new FormData();

		if (value && typeof value === 'object') {
			Object.keys(value).forEach((key) => {
				data.append(key, value[key]);
			});
		} else {
			data.append('value', value ? value : '');
		}

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + response_id + `/questions/` + question_id,
			data,
		});

		if (callback) {
			callback();
		}

		return dispatch(getSurveyAction(ticket_id, response_id));
	};

/**
 * Post survey contact method
 * @param {int} survey_id Survey id
 * @param {int} contact_method Contact method value
 * @param {function} callback callback method on successful api request
 * @returns
 */
export const setContactMethodSurveyAction =
	({ ticket_id, response_id, contact_method, callback = false }) =>
	async (dispatch) => {
		const state = store.getState();

		var data = new FormData();

		data.append('contact_method', contact_method);

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + response_id + `/method`,
			data,
		});

		if (callback) {
			callback();
		}

		return dispatch(getSurveyAction(ticket_id, response_id));
	};

/**
 * Post survey contact type
 * @param {int} survey_id Survey id
 * @param {int} contact_type Contact type value
 * @param {function} callback callback type on successful api request
 * @returns
 */
export const setContactTypeSurveyAction =
	({ ticket_id, response_id, contact_type, callback = false }) =>
	async (dispatch) => {
		const state = store.getState();

		var data = new FormData();

		data.append('contact_type', contact_type);

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + response_id + `/type`,
			data,
		});

		if (callback) {
			callback();
		}

		return dispatch(getSurveyAction(ticket_id, response_id));
	};

/**
 * Post survey contacted at date
 * @param {int} survey_id Survey id
 * @param {date} contact_type Contacted at date value
 * @param {function} callback callback type on successful api request
 * @returns
 */
export const setContactedAtSurveyAction =
	({ ticket_id, response_id, contacted_at, callback = false, callbackError = false }) =>
	async (dispatch) => {
		const state = store.getState();

		var data = new FormData();

		contacted_at = parseDateOffset(contacted_at).toISOString().split('.')[0] + 'Z';
		contacted_at = contacted_at.replace('T', ' ').replace('Z', '');

		data.append('contacted_at', contacted_at);

		try {
			const response = await apiRequest({
				method: 'post',
				url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + response_id + `/contacted`,
				data,
			});

			if (callback) {
				callback();
			}
		} catch (error) {
			if (callbackError) {
        callbackError();
      }
		}

		return dispatch(getSurveyAction(ticket_id, response_id));
	};

/**
 * Lock/unlock Survey to save history
 * @param {int} ticket_id Ticket id
 * @param {int} survey_id Survey id
 * @param {boolean} save_history value to set
 * @returns
 */
export const setLockSurveyAction =
	({ ticket_id, survey_id, save_history, get_survey = true }) =>
	async (dispatch) => {
		const state = store.getState();

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + survey_id + `/history`,
			data: { save_history: save_history ? 1 : 0 },
		});

		if (get_survey) {
			return dispatch(getSurveyAction(ticket_id, survey_id));
		}

		return;
	};

/**
 * Sign Survey
 * @param {int} ticket_id Ticket id
 * @param {int} survey_id Survey id
 * @returns
 */
export const setSignSurveyAction =
	({ ticket_id, survey_id }) =>
	async (dispatch) => {
		const state = store.getState();

		await dispatch(
			setLockSurveyAction({
				survey_id: survey_id,
				ticket_id: ticket_id,
				save_history: 1,
				get_survey: false,
			})
		);

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + `/tickets/` + ticket_id + `/surveys/` + survey_id + `/sign`,
		});

		return dispatch(getSurveyAction(ticket_id, survey_id));
	};

/**
 * Clear survey reducer
 * @returns
 */
export const clearSurveyAction = () => async (dispatch) => {
	return dispatch({
		type: CLEAR_SURVEY,
	});
};

export const deleteSurveyAction =
	(ticket_id, survey_id, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		await apiRequest({
			method: 'delete',
			url: state.config.apihost + '/tickets/' + ticket_id + '/surveys/' + survey_id,
			submit_form: false,
			success_message: translate('alert_delete_ticket_survey_success'),
		});

		if (callback) {
			callback();
		} else {
      if (state.tickets.ticket.scopes.includes(TICKET.SCOPES.OVERVIEW.SURVEYS_HISTORY)) {
				await dispatch(getUserTicketSurveysAction(ticket_id, state.tickets.ticket.users_id));
			} else {
				await dispatch(getTicketSurveysAction(ticket_id));
			}
		}
	};

const surveyActions = {
	getSurveyAction,
	assignSurveyAction,
	postAnswerAction,
	setLockSurveyAction,
	clearSurveyAction,
	setSignSurveyAction,
	setContactMethodSurveyAction,
	setContactTypeSurveyAction,
	setContactedAtSurveyAction,
	deleteSurveyAction,
};

export default surveyActions;
