// React core
import React from 'react';

// redux && redux store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history, store, persistor } from './store.js';
import { LocalizeProvider } from 'react-localize-redux';

// Our history
import { ConnectedRouter } from 'connected-react-router';

// Our "pages" main components
import Main from './Main.js';
// import { StylesProvider } from '@material-ui/core/styles';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import CacheBuster from 'react-cache-buster';
import LinearProgress from '@mui/material/LinearProgress';

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  
	return (
    <CacheBuster
      currentVersion={process.env.REACT_APP_ACVERSION}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<LinearProgress />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<LocalizeProvider store={store}>
            <Main />
					</LocalizeProvider>
				</ConnectedRouter>
			</PersistGate>
		</Provider>
    </CacheBuster>
	);
}

export default App;
